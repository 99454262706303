import { Injectable, ViewContainerRef } from '@angular/core';
import {
  FilterTypes,
  FilterValue,
  FilterWithValue,
  FiltersConfig,
  FiltersModels,
  FiltersOptionType,
  FiltersValues,
  NumberFormatOption,
  NumberRangeFilterType
} from '../models/filters.types';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  Observable, Subject, BehaviorSubject, ReplaySubject
} from 'rxjs';
import { DatePipe, DecimalPipe } from '@angular/common';

interface ConfigOptions {
  useInitValues?: boolean;
}

@Injectable()
export class FiltersService {
  readonly filtersConfig: Observable<FiltersConfig>;

  readonly filtersValues: Observable<FiltersValues>;

  readonly toolbarAnchors: Observable<ViewContainerRef[]>;

  private filtersCurrentModels: FiltersModels;

  // used to add a delay when emitting too many values too fast
  private readonly debouncer = new Subject<string>();

  private readonly filtersConfigChange = new BehaviorSubject<FiltersConfig>(undefined);

  private readonly filtersValuesChange = new ReplaySubject<FiltersValues>(1);

  private readonly toolbarAnchorsChange = new BehaviorSubject<ViewContainerRef[]>([]);

  private forceUpdate = false;

  constructor(private datepipe: DatePipe, private decimalPipe: DecimalPipe) {
    this.filtersConfig = this.filtersConfigChange.asObservable();
    this.filtersValues = this.filtersValuesChange.asObservable();
    this.toolbarAnchors = this.toolbarAnchorsChange.asObservable();

    this.debouncer.pipe(
      debounceTime(250),
      distinctUntilChanged((x, y) => {
        if (this.forceUpdate) return false;

        return x === y;
      })
    ).subscribe(this.emitNewValueChange.bind(this));
  }

  setFiltersConfig(config: FiltersConfig, options?: ConfigOptions) {
    this.initValues(config, options);
  }

  /**
   * Updates the value of a filter. Emits an event notifying the change
   * @param key name of the filter to be changed
   * @param value new value
   */
  async applyFilter(key: string, value: any) {
    if (!(this.filtersCurrentModels?.[key])) return;

    const targetFilter = this.filtersCurrentModels[key];

    const currentValue = targetFilter.value;
    const valueChanged = JSON.stringify(currentValue) !== JSON.stringify(value);

    if (valueChanged) {
      targetFilter.value = value?.length === 0 ? null : value;
      targetFilter.displayValue = await this.getDisplayValue(targetFilter);
      targetFilter.applyDisabled = value == null;
      this.deferNewValue();
    }
  }

  /**
   * Clear one or all filters values
   * @param key specific filter to be cleared
   */
  clearFilters(key?: string) {
    if (key) {
      const filter = this.filtersCurrentModels[key];

      // if filer is required we prevent clearing the current value
      if (filter.required) return;

      filter.value = null;
      filter.displayValue = '';
      filter.applyDisabled = true;
      this.deferNewValue();
    } else {
      const config = this.filtersConfigChange.value;
      this.initValues(config);
    }
  }

  /**
   * Returns a dictionary with all the not null values of the current configuration
   * @returns object with all values or null
   */
  getActiveValues() {
    const values: Record<string, any> = {};

    if (this.filtersCurrentModels) {
      Object.keys(this.filtersCurrentModels).forEach(key => {
        const filter = this.filtersCurrentModels[key];

        if (filter.value) {
          const value = this.getFilterValue(filter);

          if (value) {
            values[key] = value;
          }
        }
      });
    }

    return Object.values(values).length ? values : null;
  }

  /**
   * Adds a new anchor reference to the list. Anchor references are used to instanciate
   * FiltersToolbarComponents.
   * @param anchorRef view container ref of the anchor
   */
  addToolbarAnchor(anchorRef: ViewContainerRef) {
    const anchors = this.toolbarAnchorsChange.value;
    if (!anchors.includes(anchorRef)) {
      anchors.push(anchorRef);
      this.toolbarAnchorsChange.next(anchors);
    }
  }

  /**
   * Removes and anchor reference from the list
   * @param anchorRef view container ref of the anchor
   */
  removeToolbarAnchor(anchorRef: ViewContainerRef) {
    const anchors = this.toolbarAnchorsChange.value;
    const index = anchors.indexOf(anchorRef);
    if (index > -1) {
      anchors.splice(index, 1);
      this.toolbarAnchorsChange.next(anchors);
    }
  }

  /**
   * Returns the custom properties of a number-range filter
   * @param config configuration of the filter
   * @param attr attribute to be found: "from" | "to"
   * @returns the custom name for that property or "from" | "to" as default
   */
  getCustomIndexValue(config: FiltersOptionType, attr: 'from' | 'to') {
    let index: string;

    if (config.type === 'number-range') {
      index = attr === 'from' ? (config.fromAttr || attr) : (config.toAttr || attr);
    }

    return index;
  }

  /**
   * Validate if a record is a correct number-range value
   * @param key key of the filter
   * @param value value to be validated
   */
  validateNumberRange(key: string, value: Record<string, number>) {
    const config = this.filtersConfigChange.value?.[key];

    if (!config) return false;

    const fromIndex = this.getCustomIndexValue(config, 'from');
    const toIndex = this.getCustomIndexValue(config, 'to');

    const { [fromIndex]: fromValue, [toIndex]: toValue } = value || {};

    if (fromValue == null && toValue == null) {
      return true;
    }

    if (fromValue != null && toValue == null) {
      return true;
    }

    if (fromValue == null && toValue != null) {
      return true;
    }

    return fromValue <= toValue;
  }

  /**
   * Parse dates to format dd-MM-yyyy for query params
   * @param fromDate
   * @param toDate
   * @returns
   */
  parseDateFromToQuery(dateRange: string) {
    const [fromDate, toDate] = dateRange?.split(' - ') || [];
    let from: string = null;
    let to: string = null;

    if (fromDate) {
      from = this.datepipe.transform(fromDate, 'dd-MM-yyyy');
    }

    if (toDate) {
      to = this.datepipe.transform(toDate, 'dd-MM-yyyy');
    }

    return [from, to];
  }

  /**
   * Initialize component values
   */
  private async initValues(config: FiltersConfig, options?: ConfigOptions) {
    const filtersModels: FiltersModels = {};

    const { useInitValues } = options || {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(config)) {
      const filter = config[key];

      let values: FilterValue;

      // preserve required current value
      if (filter.required && !filter.initValue && this.filtersCurrentModels?.[key]?.value) {
        values = this.filtersCurrentModels[key];
      } else {
        const filterValue = useInitValues ? filter.initValue : null;
        values = this.getValueFromParam(filter.type, filterValue);
        // set display value
        // eslint-disable-next-line no-await-in-loop
        values.displayValue = await this.getDisplayValue({ ...filter, ...values });
      }

      const filtersValues: FilterWithValue = {
        ...filter,
        ...values,
      };

      filtersModels[key] = filtersValues;
    }

    this.filtersCurrentModels = filtersModels;

    this.filtersConfigChange.next(config);

    this.deferNewValue(true);
  }

  /*
   * set filters values from string dictionary
   * adapting them to the format used by this component
   */
  private getValueFromParam(type: FilterTypes, filterValue: string) {
    let value: FilterValue;

    switch (type) {
      case 'switch': {
        let val = false;

        try {
          val = JSON.parse(filterValue);
        } catch (e) {
          val = null;
        }

        value = { value: val };
        break;
      }
      case 'date': {
        const val = filterValue && this.parseDate(filterValue);

        value = { value: val };
        break;
      }
      case 'date-range': {
        const range = filterValue && this.parseDateRange(filterValue);
        const applyDisabled = true;

        value = { value: range, applyDisabled };
        break;
      }
      case 'select': {
        let val = null;

        if (filterValue) {
          val = Array.isArray(filterValue) ? filterValue : [filterValue];
        }

        value = { value: val, applyDisabled: val == null };
        break;
      }
      default:
        value = { value: filterValue || '', applyDisabled: filterValue == null };
        break;
    }

    return value;
  }

  /**
   * Emits a new filter change in a String dictionary format
   */
  private deferNewValue(forceUpdate?: boolean) {
    const filtersState: FiltersValues = {};

    Object.keys(this.filtersCurrentModels).forEach(key => {
      const filter = this.filtersCurrentModels[key];

      filtersState[key] = {
        value: filter.value,
        displayValue: filter.displayValue,
        applyDisabled: filter.applyDisabled,
      };
    });

    const state = JSON.stringify(filtersState);

    this.forceUpdate = forceUpdate;

    this.debouncer.next(state);
  }

  private emitNewValueChange(state) {
    const filtersValues = state !== '{}' ? JSON.parse(state) as FiltersValues : null;

    this.filtersValuesChange.next(filtersValues);
  }

  /**
   * takes a date object and returns a string in dd/MM/yyyy or ISO format
   * @param date date to be formatted
   * @param isoFormat return date on ISO format
   * @returns string with the formatted date or null if date is not valid
   */
  private getFormattedDate(date: Date, isoFormat?: boolean) {
    const dateFormat = isoFormat ? 'yyyy-MM-ddTHH:mm:ssZ' : 'dd/MM/yyyy';
    return this.isValidDate(date) ? this.datepipe.transform(date, dateFormat) : null;
  }

  /**
   * Construct a date object from a string
   * @param date string with the formatted date
   * @returns Date object or null
   */
  private parseDate(date: string) {
    return date ? new Date(date) : null;
  }

  /**
   * takes a string and returns and object with the formatted dates.
   * @param dateRange string in "{start} - {end}" format.
   * @returns Object with start and end prop. with Date Obj or null if string is invalid
   */
  private parseDateRange(dateRange: string) {
    if (!dateRange) return null;

    const [startStr, endStr] = dateRange?.split(' - ') || [];

    const start = startStr ? new Date(startStr) : null;

    const end = endStr ? new Date(endStr) : null;

    if (!(this.isValidDate(start) && this.isValidDate(end))) {
      return null;
    }

    return { start, end };
  }

  private isValidDate(d: Date) {
    return d instanceof Date && !Number.isNaN(d.getTime());
  }

  private getFilterValue(filter: FilterWithValue) {
    let value;

    switch (filter.type) {
      case 'date':
        value = this.getFormattedDate(filter.value, true);
        break;
      case 'date-range': {
        const start = this.getFormattedDate(filter.value.start, true);
        const end = this.getFormattedDate(filter.value.end, true);

        if (start || end) {
          value = `${start} - ${end}`;
        }

        break;
      }
      case 'select':
        value = filter.value.length ? filter.value : null;
        break;
      default:
        value = filter.value;
    }

    return value;
  }

  /**
  * Returns a string with the respective value label depending on the filter type
  */
  private async getDisplayValue(filter: FilterWithValue) {
    const { value } = filter || {};

    let newDisplayValue = '';

    // value not null
    if (value) {
      newDisplayValue = await this.getDisplayValueLabel(filter);
    }

    return newDisplayValue;
  }

  private async getDisplayValueLabel(filter: FilterWithValue) {
    const { value } = filter;
    let newDisplayValue: string;

    // get display value string depending on filter type
    switch (filter.type) {
      case 'date':
        newDisplayValue = this.getFormattedDate(value);
        break;
      case 'date-range': {
        const start = this.getFormattedDate(value.start);
        const end = this.getFormattedDate(value.end);

        // if at least one date is valid
        if (start || end) {
          newDisplayValue = `${start} - ${end}`;
        }
        break;
      }
      case 'select': {
        // get selected value labels and return string in "x,y,z" format
        const options = await filter.options;

        const selectedOptions = value
          .map(val => val != null && options.find(op => op.value === val)?.label)
          .filter(selected => !!selected);

        newDisplayValue = selectedOptions.join();
        break;
      }
      case 'radio': {
        // get selected value label
        const options = await filter.options;
        newDisplayValue = options.find(opt => opt.value === value)?.label;
        break;
      }
      case 'switch': {
        // switch type doesn't use display value
        break;
      }
      case 'number': {
        newDisplayValue = `${this._formatNumber(value, filter.format) || '∞'}${filter.unit || ''}`;
        break;
      }
      case 'number-range': {
        newDisplayValue = this._getNumberRangeDisplayLabel(value, filter);
        break;
      }
      default:
        // if value is array, get string in "x,y,z" format
        if (Array.isArray(value)) {
          newDisplayValue = value.length ? value.toString() : null;
        } else {
          newDisplayValue = value;
        }

        break;
    }

    return newDisplayValue;
  }

  /**
   * Get string with the number range value with format: `${value}${unit} - ${value}${unit}`
   */
  private _getNumberRangeDisplayLabel(value: any, filter: FilterWithValue) {
    let label = '';
    const filterConfig = filter as NumberRangeFilterType;
    const fromIndex = this.getCustomIndexValue(filterConfig, 'from');
    const toIndex = this.getCustomIndexValue(filterConfig, 'to');

    const from = value[fromIndex]?.toString() || '';
    const to = value[toIndex]?.toString() || '';

    // if at least one value is present
    if (from || to) {
      label = `${filterConfig.label}: ${this._formatNumber(from, filterConfig.format) || '∞'}${filterConfig.unit || ''}`
        + ` - ${this._formatNumber(to, filterConfig.format) || '∞'}${filterConfig.unit || ''}`;
    }

    return label;
  }

  private _formatNumber(number: number | string, format?: NumberFormatOption) {
    let formattedNumber: string;

    if (number == null) {
      return number;
    }

    switch (format) {
      case 'w-decimals':
        formattedNumber = this.decimalPipe.transform(number, '1.0-2');
        break;
      case 'wo-decimals':
        formattedNumber = this.decimalPipe.transform(number, '1.0-0');
        break;
      case 'wo-decimals-or-separators':
        formattedNumber = parseInt(number.toString(), 10).toString();
        break;
      default:
        formattedNumber = number.toString();
        break;
    }

    return formattedNumber;
  }
}
