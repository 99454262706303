import {
  Component, OnInit, OnDestroy, ViewChildren, QueryList, ViewChild, ElementRef, AfterViewInit
} from '@angular/core';
import { AppAnimations } from 'src/app/_shared/animations/animations';
import { MatMenuTrigger } from '@angular/material/menu';
import { FiltersAbstract } from '../classes/filters.abstract';
import {
  FiltersConfig, FiltersValues
} from '../../models/filters.types';
import {
  DateRange,
  MatDateRangeInput,
  MatDateRangePicker,
  MatDatepickerInputEvent
} from '@angular/material/datepicker';

@Component({
  selector: 'agd-filters-toolbar',
  templateUrl: './filters-toolbar.component.html',
  styleUrls: ['./filters-toolbar.component.scss'],
  animations: [AppAnimations.expand],
})
export class FiltersToolbarComponent extends FiltersAbstract implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(MatMenuTrigger) menuTriggers: QueryList<MatMenuTrigger>;

  @ViewChild('filtersElem', { read: ElementRef }) filtersElRef: ElementRef<HTMLElement>;

  // Used to display the current configuration
  config: FiltersConfig;

  values: FiltersValues = {};

  showScrollCtrls = false;

  _currentValues: FiltersValues = {};

  _display = true;

  private observer: ResizeObserver;

  ngOnInit(): void {
    super.ngOnInit();
    // Subscribe to values updates
    this.subs.sink = this.filtersService.filtersValues.subscribe(() => {
      this._currentValues = JSON.parse(JSON.stringify(this.values));
      this.updateValueCount();
      this.scrollCtrlsVisibility();
    });
  }

  ngAfterViewInit(): void {
    // Observe rize changes in toolbar element to calculate scroll controls visibility
    this.observer = new ResizeObserver((entries) => {
      entries.forEach(this.scrollCtrlsVisibility.bind(this));
    });

    this.observer.observe(this.filtersElRef.nativeElement);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.observer?.unobserve(this.filtersElRef.nativeElement);
  }

  resetInput(key) {
    if (this._currentValues?.[key]?.value) {
      this._currentValues[key].value = this.values?.[key]?.value && JSON.parse(JSON.stringify(this.values?.[key]?.value));
      this._currentValues[key].applyDisabled = this.values?.[key]?.applyDisabled;
    }
  }

  applyFilter(key: string, event: Event | string | number | boolean) {
    const newValue = event instanceof KeyboardEvent ? (<HTMLInputElement>event.target).value : event;

    this.filtersService.applyFilter(key, newValue);
    this.menuTriggers?.forEach(trigger => trigger.closeMenu());
  }

  clearFilters(key?: string, menu?: MatDateRangePicker<Date>) {
    super.clearFilters(key);

    if (menu) {
      menu.close();
    } else {
      this.menuTriggers?.forEach(trigger => trigger.closeMenu());
    }
  }

  dateRangeStartChanged(event: MatDatepickerInputEvent<Date>, key: string, input: MatDateRangeInput<DateRange<Date>>) {
    const value = {
      start: event.value,
      end: input.value?.end || null,
    };

    this.initFilterRecord(key, value);
  }

  dateRangeEndChanged(event: MatDatepickerInputEvent<Date>, key: string) {
    const { value } = event;

    if (!value) return;

    const rangeFilter = this._currentValues[key];

    rangeFilter.value.end = value;
  }

  /**
   * Evaluar si un rango de fecha solo tiene 1 valor seleccionado.
   * Si solo tiene fecha "desde", se aplica ese mismo valor a la fecha "hasta"
   * @param key key del filtro a evaluar
   */
  patchDateRangeValue(key: string) {
    if (this.config[key].type !== 'date-range') return;

    const filter = this._currentValues[key];

    if (filter.value?.start && filter.value?.end == null) {
      filter.value.end = filter.value.start;
    }

    this.emitDateRange(key);
  }

  changeDisplay(visibility: boolean) {
    this._display = visibility;
  }

  /**
   * Calculates if the element is partially visible in the toolbar, scrolls to it if it is.
   * @param el Reference to the clicked item
   */
  focusScroll(el: HTMLElement) {
    const styles = this.getElementStyles(el);
    const relativeX = Math.floor(el.getBoundingClientRect().left - el.parentElement.getBoundingClientRect().left);
    const overflowingLeft = relativeX < styles.parentPaddingLeft;
    const overflowingRight = relativeX + el.clientWidth + styles.parentPaddingRight > el.parentElement.clientWidth;

    if (overflowingLeft) {
      this.scrollLeft(el);
    } else if (overflowingRight) {
      this.scrollRight(el);
    }
  }

  /**
   * Scrolls the toolbar to the next invisible filter in the selected direction.
   * @param direction L to scroll Left, R to scroll Right
   */
  scrollNextElem(direction: 'L' | 'R') {
    const { children } = this.filtersElRef.nativeElement;
    const containerRect = this.filtersElRef.nativeElement.getBoundingClientRect();
    const startIndex = direction === 'L' ? children.length - 1 : 0;
    const step = direction === 'L' ? -1 : 1;

    for (let i = startIndex; direction === 'L' ? i >= 0 : i < children.length; i += step) {
      const child = children.item(i) as HTMLElement;
      const childRect = child.getBoundingClientRect();
      const styles = this.getElementStyles(child);

      if (direction === 'L' && childRect.left < containerRect.left) {
        this.scrollLeft(child);
        return;
      } if (direction === 'R' && childRect.right > containerRect.right - styles.parentPaddingRight) {
        this.scrollRight(child);
        return;
      }
    }
  }

  private scrollLeft(el: HTMLElement) {
    const styles = this.getElementStyles(el);
    el.parentElement.scrollTo({ behavior: 'smooth', left: el.offsetLeft - styles.parentPaddingLeft });
  }

  private scrollRight(el: HTMLElement) {
    const styles = this.getElementStyles(el);
    const offsetRight = (styles.parentPaddingRight + styles.elemMarginLeft / 2);
    const left = (el.offsetLeft + el.clientWidth) - (el.parentElement.clientWidth - offsetRight);
    el.parentElement.scrollTo({ behavior: 'smooth', left });
  }

  private getElementStyles(el: HTMLElement) {
    const parentStyles = window.getComputedStyle(el.parentElement);
    const parentPaddingLeft = parseInt(parentStyles.paddingLeft, 10);
    const parentPaddingRight = parseInt(parentStyles.paddingRight, 10);
    const elemMarginLeft = parseInt(window.getComputedStyle(el).marginLeft, 10);

    return {
      parentPaddingLeft,
      parentPaddingRight,
      elemMarginLeft,
    };
  }

  private scrollCtrlsVisibility() {
    if (!this.filtersElRef) return;

    const toolbarElem = this.filtersElRef.nativeElement;

    setTimeout(() => {
      this.showScrollCtrls = toolbarElem.clientWidth < toolbarElem.scrollWidth;
    });
  }

  /**
   * Emite un nuevo valor para un rango de fecha, transformando los valores a fechas de tipo Date
   * @param key key del filtro a emitir
   */
  private emitDateRange(key: string) {
    if (this.config[key].type !== 'date-range') return;

    const rangeFilter = this._currentValues[key];

    const { start, end } = rangeFilter.value || {};

    if (typeof start === 'string') {
      rangeFilter.value.start = new Date(start);
    }

    if (typeof end === 'string') {
      rangeFilter.value.end = new Date(end);
    }

    this.applyFilter(key, rangeFilter.value);
  }
}
