<div class="notification" (click)="onClick()" [ngClass]="{ read: notification.visto }">
    <!-- TODO: descomentar cuando se implemente el borrado de notificaciones
  <button class="material-icons delete-icon" title="Eliminar Notificacion" data-toggle="tooltip" data-placement="top"
    (click)="deleteNotification($event)">
    delete
  </button>
  -->
    <div class="notification-title">
        <mat-icon [svgIcon]="notification.iconoModulo" class="app-icon"></mat-icon>
        <h2>{{ notification.nombreModulo }}</h2>
    </div>

    <div class="notification-body">
        <div>
            <h3>{{ notification.titulo }}</h3>
            <p>
                {{ notification.mensaje }}
            </p>
        </div>
        <span
            *ngIf="(notification.grilla_id && notification.modulo_id) || notification.url || notification.modal"
            class="material-icons"
        >
            arrow_forward
        </span>
        <div *ngIf="notification.image_url" class="img-container">
            <img [src]="notification.image_url" width="100px" height="auto" alt="{{ notification.titulo }}" />
            <mat-icon>play_circle</mat-icon>
        </div>
    </div>
    <small>{{ notification.fecha | date: (oldNotification ? 'dd/MM/yyyy HH:mm' : 'HH:mm') }}</small>
</div>
