<h5>Seleccione una fecha o rango</h5>
<div class="dates-display">
    <div [ngClass]="{ active: targetInput === 'from', value: from }">
        Desde
        <span class="primary" *ngIf="from">{{ from | date: 'dd/MM/yyyy' }}</span>
        <span class="spaced" *ngIf="!from">————</span>
    </div>
    <div [ngClass]="{ active: targetInput === 'to', value: to }">
        Hasta
        <span class="primary" *ngIf="to">{{ to | date: 'dd/MM/yyyy' }}</span>
        <span class="spaced" *ngIf="!to">————</span>
    </div>
</div>
<div class="date-ctrls">
    <button mat-icon-button (click)="previous()">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <div class="view-selector">
        <button mat-button class="date-label" (click)="changeSelection('year')">
            {{ periodLabel | date: 'MMMM' }}
        </button>
        <button mat-button class="date-label" (click)="changeSelection('multi-year')">
            {{ periodLabel | date: 'yyyy' }}
        </button>
    </div>
    <button mat-icon-button (click)="next()">
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>
