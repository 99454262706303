import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

interface PushTagDocumentConfiguration {
  modulo: string;
  documento?: string | Promise<string>;
  seccion?: string;
  extension?: string;
  param_1?: string | number;
}
interface ConfigDataTagManager {
  [key: string]: string | Object | string[];
  items?: string[];
  tipo?: string;
}
interface PushTagConfiguration {
  event: string;
  configData: ConfigDataTagManager;
  document?: PushTagDocumentConfiguration;
}

export const ACTION_LIST = {
  VIEW: 'visualizacion',
  INTERACT: 'interaccion',
};

export const ORDER_STEPS = {
  PRICE_PAGE: 'Pagina de Precios',
  DEPOT: 'Seleccion de Acopios',
  HARVEST: 'Seleccion de Cosecha',
  QUANTITY: 'Kgs a vender',
  ACCREDITATION_METHOD: 'Metodo de Acreditacion',
  ORDER_SUMMARY: 'Resumen de venta',
  CONFIRMATION: 'Confirmacion',
};

@Injectable({
  providedIn: 'root'
})
export class GoogeTagService {
  private readonly androidAppId = 'agd.unco.clientes';

  constructor(private readonly googleTagManagerService: GoogleTagManagerService) {
    this.validateAppPlatform();
  }

  /**
   * Enviar evento de error.
   * @param keyDataConfig valores key:value parametrizables a enviar.
   * @param evento opcional - nombre de evento error - default: 'error'
   * @param docData opcional - documento
   */
  sendErrorEvent(keyDataConfig: Record<string, string | number>, evento?: string, docData?: PushTagDocumentConfiguration) {
    this.sendPushTagEvent({
      event: evento || 'error',
      configData: {
        ...keyDataConfig
      },
      document: docData,
    });
  }


  /**
   * Enviar evento de descarga exitosa.
   * @param docData informacion del Documento
   */
  sendDownloadEvent(docData: PushTagDocumentConfiguration) {
    this.sendPushTagEvent({
      event: 'descarga',
      configData: {
        accion: 'interaccion'
      },
      document: docData,
    });
  }

  /**
   * Enviar evento de login.
   * @param value Informacion del usuario que realizo login.
   */
  sendLoginEvent(value: Object) {
    this.sendPushTagEvent({
      event: 'login',
      configData: {
        user_info: value
      }
    });
  }

  /**
   * Enviar evento de paginacion
   * @param value URL actual del usuario.
   */
  sendPaginationEvent(value: string) {
    this.sendPushTagEvent({
      event: 'page_view',
      configData: {
        page_location: value
      }
    });
  }

  /**
   * Enviar evento de CUIT activo.
   * @param value Informacion del CUIT activo
   */
  sendActiveCuitEvent(value: Object) {
    this.sendPushTagEvent({
      event: 'active_cuit',
      configData: {
        active_cuit: value
      }
    });
  }

  /**
 * Enviar de accion aceptar/rechazar TyC de Venta de Granos.
 * @param value Aceptado/Rechazado
 */
  sendPermisoVentaTyC(value: string) {
    this.sendPushTagEvent({
      event: 'permiso_de_venta',
      configData: {
        accion: 'interaccion',
        estado: value
      }
    });
  }

  /**
   * Enviar evento de items a Google Tag Manager
   * @param keyDataConfig valores key:value parametrizables a enviar.
   * @param itemsValue valores Array[] a enviar
   * @param evento tipo de evento
   * @param tipo
   */
  sendItemsEvent(keyDataConfig: Record<string, string | number> | {}, itemsValue: Array<any>, evento: string, tipo?: string) {
    const configData = {
      items: itemsValue,
      ...(tipo && { tipo }),
      ...keyDataConfig
    };

    this.sendPushTagEvent({
      event: evento,
      configData
    });
  }

  /**
 * Evento 'Push Tag' de Google Tag Manager.
 * @param PropertyValues Objeto de configuracion push tag.
 */
  private sendPushTagEvent(pushTagConfig: PushTagConfiguration) {
    const {
      configData, event, document
    } = pushTagConfig;
    const {
      modulo, documento, seccion, extension, param_1
    } = document || {};

    const tagObject = {
      event,
      ...configData,
      ...(document && {
        modulo,
        ...(documento && {
          documento,
        }),
        ...(seccion && {
          seccion,
        }),
        ...(extension && {
          extension,
        }),
        ...(param_1 && {
          param_1,
        })
      })
    };
    this.googleTagManagerService.pushTag(tagObject);
  }

  private validateAppPlatform() {
    // validar si la app es una app de android
    if ('getInstalledRelatedApps' in navigator && typeof navigator.getInstalledRelatedApps === 'function') {
      navigator.getInstalledRelatedApps().then((installedApps) => {
        const isAndroidApp = installedApps.find(app => app.id === this.androidAppId);

        // registrar cookie de identificacion si se trata de una app de android
        if (isAndroidApp) {
          const date = new Date();
          // tiempo de expiracion 1 semana
          date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
          const expires = `expires=${date.toUTCString()}`;

          const cookieName = 'app-platform';
          const value = `Android Playstore ${isAndroidApp.version}`;

          document.cookie = `${cookieName}=${value}; ${expires}; path=/`;
        }
      });
    }
  }
}
