
import {
  Component, OnInit, Output, EventEmitter, Input
} from '@angular/core';
import { Notification } from '../../models/notification.model';
import { PublicFilesService } from 'src/app/_core/services/public-files/public-files.service';

interface IResources {
  srcset: string;
  src: string;
  loaded: boolean;
  error: boolean;
}

@Component({
  selector: 'app-notification-new-features',
  templateUrl: './notification-new-features.component.html',
  styleUrls: ['./notification-new-features.component.scss'],
})
export class NotificationNewFeaturesComponent implements OnInit {
  @Input() notification: Notification;

  @Output() close = new EventEmitter();

  loading = true;

  loadingFailed = false;

  resources: IResources[];

  title: string;

  description: string;

  actionLabel: string;

  constructor(private path: PublicFilesService) { }

  ngOnInit(): void {
    if (this.notification) {
      this.loading = true;

      const modalConfig = this.notification.modal?.web;

      if (!modalConfig) {
        this.loading = false;
        this.loadingFailed = true;
        return;
      }

      this.title = modalConfig.titulo;
      this.description = modalConfig.descripcion;
      this.actionLabel = modalConfig.etiquetaBoton || 'cerrar';
      const slides = modalConfig.imagenes || [];

      this.resources = slides.map((srcset) => {
        const set = srcset.map((src) => this.path.getPublicImageFullPath(src));

        return {
          loaded: false,
          error: false,
          src: set[0],
          srcset: set.reduce((acc, value, i) => `${acc && `${acc}, `}${value} ${i + 1}x `, ''),
        };
      });

      if (this.resources.length === 0) {
        this.loading = false;
      }
    }
  }

  /**
   * Emits event when media is closed.
   */
  closeModal() {
    this.close.emit('closed');
  }

  actionClick() {
    this.close.emit('click');
  }

  resourceLoaded(index, error = false) {
    this.resources[index].loaded = true;
    this.resources[index].error = error;
    if (this.resources.every((asset) => asset.loaded)) this.loading = false;
  }
}
