import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'agd-date-range-header',
  templateUrl: './date-range-header.component.html',
  styleUrls: ['./date-range-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeHeaderComponent implements OnDestroy {
  targetInput: 'from' | 'to';

  from: Date;

  to: Date;

  private readonly _destroyed = new Subject<void>();

  private readonly subs = new SubSink();

  constructor(
    private readonly _calendar: MatCalendar<Date>,
    private readonly _dateAdapter: DateAdapter<Date>,
    cdr: ChangeDetectorRef
  ) {
    const selected = this._calendar.selected as DateRange<Date>;

    if (selected?.start) {
      this.from = selected.start;
      this.to = selected.end;
      this.targetInput = null;
    } else {
      this.targetInput = 'from';
    }

    this.subs.sink = this._calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        if (this._calendar.selected === null) {
          this.from = null;
          this.to = null;
          this.targetInput = 'from';
        }
        cdr.markForCheck();
      });

    this.subs.sink = this._calendar.selectedChange
      .pipe(takeUntil(this._destroyed))
      .subscribe((val) => {
        if ((this.from > val) || this.targetInput === 'from' || this.targetInput === null) {
          this.from = val;
          this.to = null;
          this.targetInput = 'to';
        } else {
          this.to = val;
          this.targetInput = null;
        }
      });
  }

  get periodLabel() {
    return this._calendar.activeDate;
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    this.subs.unsubscribe();
  }

  changeSelection(mode: 'month' | 'year' | 'multi-year') {
    this._calendar.currentView = mode;
  }

  previous() {
    this.changeActiveDate(-1);
  }

  next() {
    this.changeActiveDate(1);
  }

  private changeActiveDate(action: 1 | -1) {
    const { currentView } = this._calendar;

    if (currentView === 'month') {
      this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, action);
    }

    if (currentView === 'year') {
      const add = action * 12;
      this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, add);
    }

    if (currentView === 'multi-year') {
      const add = action * 24;
      this._calendar.multiYearView.activeDate = this._dateAdapter.addCalendarYears(this._calendar.multiYearView.activeDate, add);
    }
  }
}
